//WEBFONTS
@font-face {
    font-family: "UniversCondensed";
    src: url("./assets/webfonts/UniversLTW04-57Condensed.woff2") format("woff2"),
        url("./assets/webfonts/UniversLTW04-57Condensed.woff") format("woff");
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: "UniversCondensed";
    src: url("./assets/webfonts/UniversLTW04-57CondensedObl.woff2")
            format("woff2"),
        url("./assets/webfonts/UniversLTW04-57CondensedObl.woff") format("woff");
    font-style: italic;
    font-stretch: condensed;
}

@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

//VARS
:root {
    --color-background: #000000;
    --color-foreground: #ffffff;

    --fsize-xl: 32px;
    --fsize-l: 24px;
    --fsize-m: 16px;

    --font-sans: "UniversCondensed";
    --font-hand: "Caveat", cursive;

    --space-s: 8px;
    --space-m: 16px;
    --space-l: 24px;
    --space-xl: 64px;
    --space-xxl: 128px;

    --content-width: 1200px;
    --video-width: 90vw;

    //Breakpoint Tablet
    @media (min-width: 768px) {
        --fsize-xl: 6vw;
        --fsize-l: 3vw;
        --fsize-m: 16px;
        --video-width: 80vw;
    }
    //Breakpoint Desktop
    @media (min-width: 1280px) {
        --fsize-xl: 6vw;
        --fsize-l: 3vw;
        --fsize-m: 16px;
        --space-xxl: 8vw;
        --video-width: 1000px;
    }
    //Breakpoint Large Desktop
    @media (min-width: 1440px) {
        --video-width: 1200px;
    }
}

//RESET
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
img {
    display: block;
    max-width: 100%;
}
a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-style: normal;
    font-weight: normal;
}

body {
    background-color: var(--color-background);
    color: var(--color-foreground);
    min-height: 100vh;
    font-family: var(--font-sans);
    font-style: normal;
    font-size: var(--fsize-m);
}
main {
    overflow: hidden;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--space-m) var(--space-l);
    border: none;
    border-radius: 100px;
    line-height: 1;
    font-size: inherit;
    font-family: inherit;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    max-height: 56px;

    &--primary {
        background-color: var(--color-foreground);
        color: var(--color-background);
    }
    &--outline {
        border: 1px solid var(--color-foreground);
        background-color: var(--color-background);
        color: var(--color-foreground);

        &:hover {
            background-color: var(--color-foreground);
            color: var(--color-background);
        }
    }
}

.copy {
    font-size: var(--fsize-l);
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: -0.02em;

    &--handwritten {
        font-family: var(--font-hand);
        text-transform: none;
        font-size: 3.5vw;
        letter-spacing: -0.04em;
        margin-right: 1vw;
    }
}

//GRID
.row {
    display: flex;
}

.text-block {
    width: 100%;
    padding: var(--space-xl) 0 var(--space-xl) 0;
    @media (min-width: 768px) {
        width: 50%;
        padding: var(--space-m) 0 0 0;
    }

    &.offset-6 {
        margin-left: 0%;
        @media (min-width: 768px) {
            margin-left: 50%;
        }
    }
}

.header {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--space-xl) var(--space-m);
    position: absolute;
    top: 0;
    z-index: 3;
    pointer-events: none;

    @media (min-width: 570px) {
        flex-direction: row;
        justify-content: space-between;
        height: 96px;
        background: var(--color-background);
        background: linear-gradient(
            0deg,
            rgba(0, 62, 255, 0) 0%,
            var(--color-background) 100%
        );
    }
    @media (min-width: 768px) {
        position: fixed;
        padding: var(--space-s) var(--space-l);
    }
    @media (min-width: 1440px) {
        padding: var(--space-m) var(--space-l);
    }

    &__logo {
        margin-bottom: var(--space-l);
        width: 50vw;

        img {
            min-width: 100%;

            @media (min-width: 570px) {
                max-width: 100px;
                min-width: unset;
            }
        }
        @media (min-width: 570px) {
            margin-bottom: 0;
            width: 100px;
            flex: 1;
        }
    }
    &__release-date {
        text-transform: uppercase;
        text-align: center;
        h3 {
            border-bottom: 1px solid var(--color-foreground);
            font-size: var(--fsize-m);
            letter-spacing: 0.03em;
        }
    }
    &__button-group {
        display: none;

        @media (min-width: 570px) {
            display: flex;
            //display: none;
            flex: 1;
            justify-content: flex-end;
        }

        .button {
            margin-left: 0;
            pointer-events: initial;
            &:first-child {
                display: block;
                margin-right: 8px;
                @media (min-width: 570px) {
                    margin-right: var(--space-m);
                }
            }
        }
    }
}

//FOOTER BUTTONS
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 16px 16px 16px;

    @media (min-width: 570px) {
        display: none;
    }

    &__button-group {
        display: flex;
        // display: none;
        width: 100%;
        justify-content: space-between;
        .button {
            display: block;
            &:first-child {
                display: block;
            }
            flex: 1;
        }
    }
}

//SECTIONS
.section {
    &--intro {
        height: 100vh;
        position: relative;
    }

    &--quote {
        min-height: 50vh;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        @media (min-width: 768px) {
            min-height: 70vh;
        }
    }
    &--stills {
        overflow: hidden;
    }
    &--interviewies {
        padding: 0 var(--space-m);
        @media (min-width: 768px) {
            padding: 0 var(--space-l);
        }
    }
    &--premieres {
        padding: var(--space-xl) var(--space-m);
        @media (min-width: 768px) {
            padding: var(--space-xxl) var(--space-l);
        }
    }
}

.preloader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    background: var(--color-background);
    pointer-events: none;

    // display: none;

    .text-wrapper {
        overflow: hidden;
        display: block;
    }
}

.keyvisual {
    // max-width: 85vw;
    min-height: 100vh;
    margin: 0 auto 0 auto;
    // position: relative;
    overflow: hidden;

    img {
        min-width: 100%;
        // max-width: 2000px;
        margin: 0 auto;
        object-fit: cover;
    }

    &__top {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        @media (min-width: 768px) {
            width: 50vw;
            top: 0;
        }
        @media (min-width: 1440px) {
            width: 800px;
        }
    }
    &__right {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 50vw;
        display: none;
        @media (min-width: 768px) {
            width: 25vw;
            display: block;
        }
        @media (min-width: 1440px) {
            width: 400px;
        }
    }
    &__bottom {
        position: absolute;
        left: 50%;
        bottom: 90px;
        transform: translate(-50%, 0%);
        width: 100vw;
        @media (min-width: 768px) {
            width: 40vw;
            bottom: -10%;
        }
        @media (min-width: 1440px) {
            width: 800px;
        }
    }
    &__left {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 40vw;
        display: none;
        @media (min-width: 768px) {
            width: 25vw;
            display: block;
        }
        @media (min-width: 1440px) {
            width: 400px;
        }
    }
    &__center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        display: none;
        @media (min-width: 768px) {
            display: block;
        }
    }
}
.trailer {
    max-width: var(--video-width);
    width: 100%;
    margin: 0 auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    @media (min-width: 768px) {
        padding-top: 5vh;
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.partner-logos {
    position: absolute;
    bottom: -56px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    @media (min-width: 768px) {
        bottom: -56px;
        width: unset;
    }
}

.quote {
    font-size: var(--fsize-xl);
    text-transform: uppercase;
    letter-spacing: -0.05em;
    line-height: 0.9;
    text-align: center;
    max-width: 100vw;
    margin: 0 auto;

    @media (min-width: 768px) {
        max-width: 70vw;
    }
}

.stills {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--space-l);
    @media (min-width: 1024px) {
        margin-bottom: var(--space-xxl);
    }

    &__image {
        margin-right: var(--space-l);
        max-width: 70vw;
        @media (min-width: 1024px) {
            margin-right: var(--space-xxl);
            max-width: 40vw;
        }
    }
}

.image-block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    &__item {
        width: 100%;
        img {
            // width: 320px;
            @media (min-width: 768px) {
                min-width: 100%;
            }
        }
    }

    &--1 {
        @media (min-width: 768px) {
            background-image: url("./assets/images/logo__bottom.svg");
            background-repeat: no-repeat;
            background-position: center left;
        }
        .image-block__item {
            // border: 1px solid green;
            &:nth-child(2) {
                margin-top: 0;
                @media (min-width: 768px) {
                    margin-top: 25vh;
                }
            }
            &:nth-child(3) {
                margin-top: 0;
                @media (min-width: 768px) {
                    margin-top: 50vh;
                }
            }
        }
    }
    &--2 {
        @media (min-width: 768px) {
            background-image: url("./assets/images/logo__left.svg");
            background-repeat: no-repeat;
            background-position: bottom right;
        }
        .image-block__item {
            // border: 1px solid green;
            margin-top: 0;
            &:nth-child(1) {
                margin-top: 0;
                order: 3;
                @media (min-width: 768px) {
                    margin-top: 50vh;
                    order: 1;
                }
            }
            &:nth-child(2) {
                margin-top: 0;
                order: 2;
                @media (min-width: 768px) {
                    margin-top: 25vh;
                    order: 2;
                }
            }
            &:nth-child(3) {
                order: 1;
                @media (min-width: 768px) {
                    order: 3;
                }
            }
        }
    }
}

.premiere {
    max-width: var(--content-width);
    margin: 0 auto;
    &__section-title {
        font-size: var(--fsize-l);
        text-transform: uppercase;
        margin-bottom: var(--space-m);
        @media (min-width: 768px) {
            margin-bottom: var(--space-l);
        }
    }
    &__row {
        border-top: 1px solid var(--color-foreground);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: var(--space-l);
        @media (min-width: 768px) {
            flex-direction: row;
        }

        &:hover {
            .premiere__image {
                opacity: 1;
                transform: scale(1) rotate(0deg);
            }
            .premiere__title,
            .premiere__date {
                opacity: 0.5;
            }
        }
    }
    &__title {
        font-size: var(--fsize-xl);
        text-transform: uppercase;
        letter-spacing: -0.02em;
        transition: opacity 300ms ease-in-out;
    }
    &__date {
        text-transform: uppercase;
        transition: opacity 300ms ease-in-out;
        letter-spacing: 0.04em;
    }
    &__image {
        padding: 16px 0;
        opacity: 1;
        transform: scale(1) rotate(0);
        transition: transform 600ms ease-in-out, opacity 300ms ease-in-out;
        object-fit: cover;
        @media (min-width: 768px) {
            padding: 16px 0;
            opacity: 0;
            transform: scale(0) rotate(-360deg);
            transition: transform 600ms ease-in-out, opacity 300ms ease-in-out;
        }
    }
}

.hidden-link {
    display: none;
    color: black;
}
